import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import App from './App';
import KKSCookie from './components/KKSCookie'

ReactGA.initialize("AW-11092531293");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <KKSCookie>
            <App>
            </App>
      </KKSCookie>
);

