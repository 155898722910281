import SeoTags from "../util/SeoTags";
import Service from "../components/Service";
import Contact from "../components/forms/Contact"

function Services() {

    return (
        <>
            <SeoTags title={'KrisKissTyle | Servicios'} description={"Maquillaje y Peinados por Kristina Malamova"} />
            <Service />
            <Contact />
        </>
    )
}

export default Services;