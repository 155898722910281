import { useAuthValue } from '../auth/AuthContext'
import { useState, useEffect } from 'react'
import { authentication } from '../auth/Firestore'
import { sendEmailVerification } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

function VerifyEmail() {

    const { currentUser } = useAuthValue()
    const [time, setTime] = useState(60)
    const { timeActive, setTimeActive } = useAuthValue()
    const navigate = useNavigate()

    useEffect(() => {
        const interval = setInterval(() => {
            currentUser?.reload()
                .then(() => {
                    if (currentUser?.emailVerified) {
                        clearInterval(interval)
                        navigate('/profile')
                    }
                })
                .catch((err) => {
                    alert(err.message)
                })
        }, 1000)
    }, [navigate, currentUser])

    useEffect(() => {
        let interval = null
        if (timeActive && time !== 0) {
            interval = setInterval(() => {
                setTime((time) => time - 1)
            }, 1000)
        } else if (time === 0) {
            setTimeActive(false)
            setTime(60)
            clearInterval(interval)
        }
        return () => clearInterval(interval);
    }, [timeActive, time, setTimeActive])

    const resendEmailVerification = () => {
        sendEmailVerification(authentication.currentUser)
            .then(() => {
                setTimeActive(true)
            }).catch((err) => {
                alert(err.message)
            })
    }

    return (
        <div className='container my-5 py-5'>
            <div className='my-5 py-5 text-center'>
                <h1>Verifica tu correo electrónico</h1>
                <p>
                    <strong>Revisa tu correo electrónico:</strong><br />
                    <span>{currentUser?.email}</span>
                </p>
                <span>Sigue las instrucciones para confirmar la creación de tu cuenta. Comprueba la carpeta de Spam si no te ha llegado.</span>
                <br />
                <button className='btn btn-secondary mt-3'
                    onClick={resendEmailVerification}
                    disabled={timeActive}
                >Reenviar Correo -- {timeActive && time}s</button>
            </div>
        </div>
    )
}

export default VerifyEmail;
