import React from "react";
import Service from "../components/Service";
import Terstimonials from "../components/Testimonials";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import SeoTags from "../util/SeoTags";

function Home() {

    return (
        <>
            <SeoTags title={'KrisKissTyle | Inicio'} description={"Maquillaje y Peinados por Kristina Malamova"} />
            <div className="home rounded-bottom d-flex">
                <div className="home-img-overlay d-flex flex-column justify-content-center  mx-auto">
                    <h1 className="text-light mx-auto text-center mb-sm-2 mb-lg-5">MAQUILLAJE Y PEINADO PARA TODOS</h1>
                    <blockquote className="blockquote text-center mx-auto">
                        <p className="page-description text-light text-center">Contacta conmigo para lucir maquillaje y/o peinado en
                            cualquier ocasión.
                        </p>
                    </blockquote>

                    <div className="d-flex flex-column justify-content-center px-0 pt-lg-5 pt-sm-1">
                        <ul className="p-0 mx-auto list-inline">
                            <li className="list-inline-item px-2"><a href="https://www.facebook.com/kriskisstyle/"
                                className="text-decoration-none">
                                <FontAwesomeIcon className="social-icon" icon={faFacebookF} ></FontAwesomeIcon>
                            </a></li>
                            <li className="list-inline-item px-2"><a href="https://www.instagram.com/kriskisstyle/"
                                className="text-decoration-none">
                                <FontAwesomeIcon className="social-icon" icon={faInstagram} ></FontAwesomeIcon>
                            </a></li>
                            <li className="list-inline-item px-2"><a href="https://wa.me/+34697575694"
                                className="text-decoration-none">
                                <FontAwesomeIcon className="social-icon" icon={faWhatsapp} ></FontAwesomeIcon>
                            </a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <Service></Service>
            <Terstimonials></Terstimonials>
        </>
    );
}

export default Home;
