import {Navigate} from 'react-router-dom'
import {useAuthValue} from './AuthContext'

export default function ProtectedRoute({children}) {
  const {currentUser} = useAuthValue()

  currentUser?.reload().then(
    ()=>{
      if (!currentUser?.emailVerified){
        return (<Navigate to="/" ></Navigate>)
      }
    }
  )

  return children
}