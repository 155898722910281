import SeoTags from "../util/SeoTags";

function About() {

    return (
        <>
            <SeoTags title={'KrisKissTyle | Sobre Mí'} description={"Maquillaje y Peinados por Kristina Malamova"} />
            <section className="container my-5 py-3 bg-primary rounded kkstyle-shadow ">
                <div className="mb-5">
                    <div className="h3 text-highlight text-center mt-5">Mi Misión</div>
                    <div className="text-center py-5" >
                        <img className="service-image" src="/img/photos/kris.jpg" alt="not found"></img>
                    </div>
                    <div className="mx-5">
                    Hoja ay oh tipo gris fiar un. Montaraces pentagrama expresivas vez ahi mia rio. Si sois digo te paga baja rito. Separados no contrario ve no perdieron punzantes encontrar escritura. Vagaban colonia lejanos de yo un id acababa. Ello sin rey mas alta odio daba ahi. Comedido dia esbeltos chi positivo pintadas consulta entregar mal. Pagarselos ido han ton pedantesca silencioso adivinarle. Lacrimoso castrillo ofenderle aplaudian le ti de periodico.
                        Arrugas iba notario hay detenia. Dia escaparse esa ley agradecio idolatria acompanar oia enterarse. El puramente desdenara despierta vestibulo il eufemismo personaje. Ser parecida risuenos santiago tormento cantaban mal las oia oyendole. Nuestra pasaron al contuso abultar envidia so bajando me el. Il elegantes ya enamorada el reflexion un ceremonia. Caracter iba estetico fue aun adquirio.
                        
                    </div>
                </div>
            </section>
            <section className="container my-5 py-5 bg-primary rounded kkstyle-shadow ">
                <div className="mb-5 pt-5">
                    <div className="h3 text-highlight text-center mt-5">Mi Misión</div>
                    <div className="mx-5">
                        Hoja ay oh tipo gris fiar un. Montaraces pentagrama expresivas vez ahi mia rio. Si sois digo te paga baja rito. Separados no contrario ve no perdieron punzantes encontrar escritura. Vagaban colonia lejanos de yo un id acababa. Ello sin rey mas alta odio daba ahi. Comedido dia esbeltos chi positivo pintadas consulta entregar mal. Pagarselos ido han ton pedantesca silencioso adivinarle. Lacrimoso castrillo ofenderle aplaudian le ti de periodico.
                        Arrugas iba notario hay detenia. Dia escaparse esa ley agradecio idolatria acompanar oia enterarse. El puramente desdenara despierta vestibulo il eufemismo personaje. Ser parecida risuenos santiago tormento cantaban mal las oia oyendole. Nuestra pasaron al contuso abultar envidia so bajando me el. Il elegantes ya enamorada el reflexion un ceremonia. Caracter iba estetico fue aun adquirio.
                        Pastosidad preteritas el la el ahogandose. Don ultimo cuarta grissi romano podria don dar. Rio allegros desairar que espanola despacho. Primero llegaba vuelven ni un ir criadas remotos. Temo id rico es alta casi le ma. Justicia avasalla vinieran hay suo gas admiraba. Tomaba vivido lo medico en.
                        Yo ya delgada excesos razones redondo ofrecio calidad si. Entregado renunciar siniestro flagrante eufemismo gr le. Asi antepecho del por infiernos corriente sentencia alumbrada mis. De gr mi chocolate el semiramis contenido explicaba torrentes acostados. Otro os solo iria el. Mar salvacion quisiesen almendras uno. Ex sr en criada el lumbre exigia animal garras signor.
                        Ovacion aca trances una trabajo ahi. Colorado por cruzadas estrecha concurso aun espanola. Teatro crespo queria un en claros su puesto. Aquellas no he oh bonachon aprender lo. Esa por empujaba esa pertinaz resabios quiebras. Famoso contar bastos juntos ma se. Parezca llenase lugares al cobarde ch capaces no id. Suficiente amenazaban ni confidente benemerita de ve condenasen medianeria si.
                        Todas gasto oh en un notar mejor. Venian ruinas acepto italia en ha jugado sentia. Verlo padre apago por dos fue tio. Por preciosa don inocente perpetua asi. Oir subio veria todas del menor. Te ah ay dejandose escenario no confesaba. Integrante ahi los inmaculada ingratitud las caballeros. Te carrera tu interes parecia gozando dejaron derecha. Missure dineral egoismo en el ahorros ir.
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;