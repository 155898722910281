import React, {useContext} from 'react'

const authContxt = React.createContext()

export function AuthContext({children, value}) {
  return (
    <authContxt.Provider value={value}>
      {children}
    </authContxt.Provider>
  )
}

export function useAuthValue(){
  return useContext(authContxt)
}