import React, { useState, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuthValue } from '../auth/AuthContext';
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { authentication } from '../auth/Firestore';
import SeoTags from '../../util/SeoTags';

function Login() {
    const { register, handleSubmit, formState: { errors } } = useForm({});
    const [error, setError] = useState('')
    const { setTimeActive } = useAuthValue()
    const navigate = useNavigate()

    const onSubmit = e => {
        //   e.preventDefault()
        // console.log(e);
        signInWithEmailAndPassword(authentication, e.UserName, e.Password)
            .then(() => {
                if (!authentication.currentUser.emailVerified) {
                    sendEmailVerification(authentication.currentUser)
                        .then(() => {
                            setTimeActive(true)
                            navigate('/verify-email')
                        })
                        .catch(err => alert(err.message))
                } else {
                    navigate('/profile')
                }
            })
            .catch((error) => {
                const code = error.code;
                switch (code) {
                    case "auth/user-not-found":
                        setError("Usuario no existe. Crea una nueva cuenta.")
                        return;
                    default:
                        setError("Ha habido algún error, crea una nueva cuenta.")
                        return;
                }
            });
    }

    return (
        <>
            <SeoTags title={"KrisKisStyle | Login"} />
            <div className='pb-5'>
                <div className=''>
                    <h1 className="font-weight-light text-center my-5 py-5 fs-3"> Introduce tu usuario y contraseña </h1>{" "}
                    {error && <div className='auth__error'>{error}</div>}
                    <form onSubmit={handleSubmit(onSubmit)} className="w-75 mx-auto" >
                        <div className="input-group-sm">
                            <label htmlFor="UserName" className="col-sm col-form-label col-form-label-sm text-highlight">Usuario</label>
                            <input className='form-control' type="text" placeholder="ejemplo@gmail.com" {...register("UserName", { obligatorio: { value: true, message: "Introducir el usuario es obligatorio" }, maxLength: { value: 180, message: "180 caracteres de máximo" } })} />
                            {errors.UserName && <p className="text-danger font-weight-light col-form-label-sm" > {" - " + errors.UserName.message} </p>}
                        </div>
                        <div className="input-group-sm">
                            <label htmlFor="Password" className="col-sm-2 col-form-label col-form-label-sm text-highlight">Contraseña</label>
                            <input className='form-control' type="password" placeholder="" {...register("Password", { required: { value: true, message: "Introducir la contraseña es obligatorio" }, maxLength: { value: 120, message: "120 caracteres de máximo" } })} />
                            {errors.Password && <p className="text-danger font-weight-light col-form-label-sm"> {" - " + errors.Password.message} </p>}
                        </div>
                        <div className="mx-auto w-100 text-center pt-3 pb-5">
                            <button className="btn btn-secondary m-3" type="submit">Login</button>
                            <div className="d-flex justify-content-between stretch flex-column">
                                <Link to="/register" className='text-decoration-none text-highlight' >
                                    <span>Crea una cuenta</span></Link>
                                <Link to="/resetpassword" className='text-decoration-none text-highlight'>
                                    <span>Olvidé la contraseña</span>
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Login;