import React from "react";
import { Helmet } from "react-helmet-async";

export default function SeoTags({title, description}) {

    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description !== undefined ? description : "Maquillaje y Peinado por Kristina Malamova"} />
            <link rel="canonical" href="https://www.kriskisstyle.com"></link>
            <meta name="robots" content="index,follow" />
        </Helmet>
    
    );
}