import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceGrinHearts, faPaintBrush, faCheckCircle, faClipboardList } from "@fortawesome/free-solid-svg-icons";


export default function RenderIcon(props) {
    // console.log(props.icon)
    switch (props.icon) {
        case "faPaintBrush":
            return (<FontAwesomeIcon size={props.size} icon={faPaintBrush}></FontAwesomeIcon>);
        case "faFaceGrinHearts":
            return (<FontAwesomeIcon size={props.size} icon={faFaceGrinHearts}></FontAwesomeIcon>);
        case "faCheckCircle":
            return (<FontAwesomeIcon size={props.size} icon={faCheckCircle}></FontAwesomeIcon>);
        case "faClipboardList":
            return (<FontAwesomeIcon size={props.size} icon={faClipboardList}></FontAwesomeIcon>);
        default:
            // console.log('Invalid Icon')
    }

    return (<FontAwesomeIcon size="5x"></FontAwesomeIcon>);
}