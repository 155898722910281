import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Photos from "../util/LoadPhotos";

function Portfolio(props) {
    const { gal } = useParams();
    const [index, setIndex] = useState(-1);
    const photos = Photos(props?.gallery ? props.gallery : gal);
    console.log(props)

    return (
        <section id={gal !== null ? gal : props.gallery} className="my-5 py-5">
            <div className="container-fluid">
                <div className="gallery-container">
                    <PhotoAlbum
                        photos={photos}
                        layout="columns"
                        targetRowHeight={650}
                        onClick={(event, photo, index) => setIndex(index)}
                    />

                    <Lightbox
                        slides={photos}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        // enable optional lightbox plugins
                        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                    />
                </div>
            </div>
        </section>
    )
}

export default Portfolio;