import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';


function Footer() {

    return (
        <>
            <section className="bottom-0 mb-0 pb-0 w-100 rounded-top foot-shadow">
                <footer className="footer pb-2 bg-highlight rounded-top w-100">
                    <div className="container-fluid w-100">
                        <p className="fs-3 text-center text-brand">Kristina Malamova</p>
                        <div className="d-flex flex- justify-content-center">
                            <ul className="list-inline">
                                <li className="list-inline-item"><a href="https://www.facebook.com/kriskisstyle/"
                                    className="text-decoration-none">
                                    <FontAwesomeIcon className="social-icon" icon={faFacebookF} ></FontAwesomeIcon>
                                </a></li>
                                <li className="list-inline-item"><a href="https://www.instagram.com/kriskisstyle/"
                                    className="text-decoration-none">
                                    <FontAwesomeIcon className="social-icon" icon={faInstagram} ></FontAwesomeIcon>
                                </a></li>
                                <li className="list-inline-item"><a href="https://wa.me/+34697575694"
                                    className="text-decoration-none">
                                    <FontAwesomeIcon className="social-icon" icon={faWhatsapp} ></FontAwesomeIcon>
                                </a></li>
                            </ul>
                        </div>
                        <hr />
                        <span className="text-center text-dark">
                            <p style={{ fontSize: '0.75rem' }} className="text-dark">Designed By <a className="text-decoration-none" rel="noreferrer" target={"_blank"} href="https://acmonroy.com">ACMonroy</a> {new Date().getFullYear()} <i
                                className="far fa-copyright"></i> - All rights reserved</p>
                        </span>
                    </div>
                </footer>
            </section>
        </>
    )

}

export default Footer;