import React from "react";
import { NavLink } from "react-router-dom"
import LoadServiceInfo from "../util/LoadServiceInfo";

function Service() {

    const serviceInfo = LoadServiceInfo()

    return (
        <>
            <section id="servicios" className="">
                <div className="container-fluid">
                    <div className="card-group mx-0 px-0 pb-5 mb-5">
                        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 gx-2 gx-lg-3 gx-xl-4 gy-2 gy-lg-3 gy-xl-4">
                            {serviceInfo?.map(({id, service}) => {
                                return (
                                    <div key={service.id} className="col">
                                        <div className="card my-5 py-5 px-md-2 px-xl-3 mh-75 mx-1 mx-md-2 d-flex flex-column h-100">
                                            <img src={service.imageSrc} className="img-fluid service-image mx-auto mb-4" alt="not found" />
                                            <div className="card-body d-flex flex-column">
                                                <h4 className="card-title mx-auto text-highlight text-center">{service.title}</h4>
                                                <p className="card-text fs-6 pt-md-2 pt-lg-3 pt-xl-4">
                                                    {service.description}
                                                </p>
                                            </div>
                                            <NavLink to={"/info/" + id} className="btn btn-thirdary mb-3 mx-auto text-center">Más Información</NavLink>
                                            {service.portfolio && 
                                            <NavLink to={"/portfolio/" + id} className="btn btn-thirdary mb-3 mx-auto text-center">Portfolio</NavLink>
                                            }                                            
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default Service;