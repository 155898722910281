
function Photos(gallery) {

    const photos =
    {
        "artistico": [
            {
                src: "/img/galeria/artistico/artistico_11.webp",
                width: 1365,
                height: 1092,
            },
            {
                src: "/img/galeria/artistico/artistico_12.webp",
                width: 1024,
                height: 851,
            },
            {
                src: "/img/galeria/artistico/artistico_13.webp",
                width: 1280,
                height: 1600,
            },
            {
                src: "/img/galeria/artistico/artistico_14.webp",
                width: 1279,
                height: 1600,
            },
            {
                src: "/img/galeria/artistico/artistico_15.webp",
                width: 1600,
                height: 1280,
            },
            {
                src: "/img/galeria/artistico/artistico_16.webp",
                width: 1067,
                height: 1600,
            },
            {
                src: "/img/galeria/artistico/artistico_17.webp",
                width: 1600,
                height: 1067,
            },
            {
                src: "/img/galeria/artistico/artistico_18.webp",
                width: 5276,
                height: 3529,
            },
            {
                src: "/img/galeria/artistico/artistico_19.webp",
                width: 2345,
                height: 4032,
            },
            {
                src: "/img/galeria/artistico/artistico_1.webp",
                width: 2160,
                height: 1620,
            },
            {
                src: "/img/galeria/artistico/artistico_20.webp",
                width: 3024,
                height: 4032,
            },
            {
                src: "/img/galeria/artistico/artistico_21.webp",
                width: 1600,
                height: 1600,
            },
            {
                src: "/img/galeria/artistico/artistico_22.webp",
                width: 1600,
                height: 1067,
            },
            {
                src: "/img/galeria/artistico/artistico_23.webp",
                width: 650,
                height: 788,
            },
            {
                src: "/img/galeria/artistico/artistico_24.webp",
                width: 4604,
                height: 4784,
            },
            {
                src: "/img/galeria/artistico/artistico_25.webp",
                width: 1080,
                height: 864,
            },
            {
                src: "/img/galeria/artistico/artistico_26.webp",
                width: 1240,
                height: 992,
            },
            {
                src: "/img/galeria/artistico/artistico_27.webp",
                width: 2659,
                height: 1899,
            },
            {
                src: "/img/galeria/artistico/artistico_28.webp",
                width: 2848,
                height: 4288,
            },
            {
                src: "/img/galeria/artistico/artistico_2.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria/artistico/artistico_3.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria/artistico/artistico_4.webp",
                width: 800,
                height: 1000,
            },
            {
                src: "/img/galeria/artistico/artistico_5.webp",
                width: 1920,
                height: 1280,
            },
            {
                src: "/img/galeria/artistico/artistico_6.webp",
                width: 1626,
                height: 1080,
            },
            {
                src: "/img/galeria/artistico/artistico_9.webp",
                width: 1600,
                height: 1280,
            },
        ],
        "eventos": [
            {
                src: "/img/galeria//eventos/eventos_10.webp",
                width: 3024,
                height: 4032,
            },
            {
                src: "/img/galeria//eventos/eventos_11.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria//eventos/eventos_12.webp",
                width: 3623,
                height: 4529,
            },
            {
                src: "/img/galeria//eventos/eventos_1.webp",
                width: 2382,
                height: 4030,
            },
            {
                src: "/img/galeria//eventos/eventos_2.webp",
                width: 3024,
                height: 4032,
            },
            {
                src: "/img/galeria//eventos/eventos_3.webp",
                width: 4032,
                height: 3024,
            },
            {
                src: "/img/galeria//eventos/eventos_4.webp",
                width: 1115,
                height: 1334,
            },
            {
                src: "/img/galeria//eventos/eventos_5.webp",
                width: 3024,
                height: 4032,
            },
            {
                src: "/img/galeria//eventos/eventos_6.webp",
                width: 3024,
                height: 4032,
            },
            {
                src: "/img/galeria//eventos/eventos_7.webp",
                width: 3024,
                height: 4032,
            },
            {
                src: "/img/galeria//eventos/eventos_8.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria//eventos/eventos_9.webp",
                width: 2477,
                height: 3057,
            },
        ],
        "fallas": [
            {
                src: "/img/galeria//fallas/fallas_12.jpeg",
                width: 756,
                height: 1024,
            },
            {
                src: "/img/galeria//fallas/fallas_10.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria//fallas/fallas_11.webp",
                width: 752,
                height: 1024,
            },
            {
                src: "/img/galeria//fallas/fallas_1.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//fallas/fallas_2.webp",
                width: 1024,
                height: 768,
            },
            {
                src: "/img/galeria//fallas/fallas_3.webp",
                width: 733,
                height: 1024,
            },
            {
                src: "/img/galeria//fallas/fallas_4.webp",
                width: 691,
                height: 1024,
            },
            {
                src: "/img/galeria//fallas/fallas_5.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//fallas/fallas_6.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//fallas/fallas_7.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//fallas/fallas_8.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria//fallas/fallas_9.webp",
                width: 1364,
                height: 1793,
            },
        ],
        "novias": [
            {
                src: "/img/galeria//novias/novias_10.jpeg",
                width: 1024,
                height: 970,
            },
            {
                src: "/img/galeria//novias/novias_11.jpeg",
                width: 1024,
                height: 935,
            },
            {
                src: "/img/galeria//novias/novias_12.jpeg",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//novias/novias_9.jpeg",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//novias/novias_1.webp",
                width: 749,
                height: 499,
            },
            {
                src: "/img/galeria//novias/novias_2.webp",
                width: 1600,
                height: 1066,
            },
            {
                src: "/img/galeria//novias/novias_3.webp",
                width: 844,
                height: 1024,
            },
            {
                src: "/img/galeria//novias/novias_4.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//novias/novias_5.webp",
                width: 651,
                height: 1024,
            },
            {
                src: "/img/galeria//novias/novias_6.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//novias/novias_7.webp",
                width: 723,
                height: 1024,
            },
            {
                src: "/img/galeria//novias/novias_8.webp",
                width: 768,
                height: 1024,
            },
        ],
        "peinado": [
            {
                src: "/img/galeria//peinados/peinados_10.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria//peinados/peinados_11.webp",
                width: 1476,
                height: 2048,
            },
            {
                src: "/img/galeria//peinados/peinados_12.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria//peinados/peinados_13.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//peinados/peinados_1.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria//peinados/peinados_2.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria//peinados/peinados_3.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria//peinados/peinados_5.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria//peinados/peinados_6.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria//peinados/peinados_7.webp",
                width: 1340,
                height: 2048,
            },
            {
                src: "/img/galeria//peinados/peinados_8.webp",
                width: 1536,
                height: 2048,
            },
            {
                src: "/img/galeria//peinados/peinados_9.webp",
                width: 1536,
                height: 2048,
            },
        ],
        "pestanas": [
            {
                src: "/img/galeria/pestanas/pestanas_5.jpg",
                width: 1024,
                height: 755,
            },
            {
                src: "/img/galeria/pestanas/pestanas_10.webp",
                width: 1024,
                height: 645,
            },
            {
                src: "/img/galeria/pestanas/pestanas_1.webp",
                width: 991,
                height: 1024,
            },
            {
                src: "/img/galeria/pestanas/pestanas_2.webp",
                width: 725,
                height: 1024,
            },
            {
                src: "/img/galeria/pestanas/pestanas_3.webp",
                width: 1024,
                height: 887,
            },
            {
                src: "/img/galeria/pestanas/pestanas_4.webp",
                width: 1024,
                height: 587,
            },
            {
                src: "/img/galeria/pestanas/pestanas_6.webp",
                width: 1172,
                height: 655,
            },
            {
                src: "/img/galeria/pestanas/pestanas_7.webp",
                width: 1024,
                height: 687,
            },
            {
                src: "/img/galeria/pestanas/pestanas_8.webp",
                width: 603,
                height: 1024,
            },
            {
                src: "/img/galeria/pestanas/pestanas_9.webp",
                width: 1024,
                height: 879,
            },
        ],
        "sesiones": [
            {
                src: "/img/galeria//sesiones/sesiones_10.webp",
                width: 1600,
                height: 1600,
            },
            {
                src: "/img/galeria//sesiones/sesiones_11.webp",
                width: 3648,
                height: 4560,
            },
            {
                src: "/img/galeria//sesiones/sesiones_12.webp",
                width: 3026,
                height: 3783,
            },
            {
                src: "/img/galeria//sesiones/sesiones_13.webp",
                width: 3540,
                height: 4425,
            },
            {
                src: "/img/galeria//sesiones/sesiones_14.webp",
                width: 5997,
                height: 4124,
            },
            {
                src: "/img/galeria//sesiones/sesiones_16.webp",
                width: 1000,
                height: 1000,
            },
            {
                src: "/img/galeria//sesiones/sesiones_1.webp",
                width: 1236,
                height: 1600,
            },
            {
                src: "/img/galeria//sesiones/sesiones_2.webp",
                width: 3840,
                height: 3840,
            },
            {
                src: "/img/galeria//sesiones/sesiones_3.webp",
                width: 1440,
                height: 1800,
            },
            {
                src: "/img/galeria//sesiones/sesiones_4.webp",
                width: 5101,
                height: 3564,
            },
            {
                src: "/img/galeria//sesiones/sesiones_5.webp",
                width: 1229,
                height: 1843,
            },
            {
                src: "/img/galeria//sesiones/sesiones_6.webp",
                width: 2048,
                height: 1364,
            },
            {
                src: "/img/galeria//sesiones/sesiones_7.webp",
                width: 1365,
                height: 2048,
            },
            {
                src: "/img/galeria//sesiones/sesiones_8.webp",
                width: 1024,
                height: 683,
            },
            {
                src: "/img/galeria//sesiones/sesiones_9.webp",
                width: 1080,
                height: 1080,
            },
        ],
        "television": [
            {
                src: "/img/galeria//television/television_10.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//television/television_11.webp",
                width: 750,
                height: 935,
            },
            {
                src: "/img/galeria//television/television_12.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//television/television_1.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//television/television_2.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//television/television_3.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//television/television_4.webp",
                width: 684,
                height: 1024,
            },
            {
                src: "/img/galeria//television/television_5.webp",
                width: 684,
                height: 1024,
            },
            {
                src: "/img/galeria//television/television_6.webp",
                width: 744,
                height: 550,
            },
            {
                src: "/img/galeria//television/television_7.webp",
                width: 686,
                height: 1024,
            },
            {
                src: "/img/galeria//television/television_8.webp",
                width: 768,
                height: 1024,
            },
            {
                src: "/img/galeria//television/television_9.webp",
                width: 1024,
                height: 1024,
            },
        ],
        
        

    };

    return photos[gallery];
}

export default Photos;