// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, doc, updateDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDJylwSl44H6NJMnnhUypimIWp8lmjIZDA",
  authDomain: "kkstylereact.firebaseapp.com",
  projectId: "kkstylereact",
  storageBucket: "kkstylereact.appspot.com",
  messagingSenderId: "580304493464",
  appId: "1:580304493464:web:05b38e3fd0fa78c1c7a9ed"
};



// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const storage = getStorage(firebaseApp);
const authentication = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

//Firestore utilities

const updateCourse = (uid, data) => { // inputs: course object and logged in user uid
  const courseRef = doc(collection(db, "/userCourses/" + uid + "/Courses"), data.id);
  updateDoc(courseRef, data);
}

export { authentication, db, storage, updateCourse };
