import React, { useState, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import { useAuthValue } from '../auth/AuthContext';
import { authentication, db } from "../auth/Firestore";
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import LoadCourses from '../../util/LoadCourses';
import SeoTags from '../../util/SeoTags';

function Register() {
    const { register, handleSubmit, formState: { errors } } = useForm({});

    const [error, setError] = useState('')
    const navigate = useNavigate()
    const { setTimeActive } = useAuthValue()
    const courses = LoadCourses();

    const onSubmit = (e) => {
        setError('')
        // Create a new user with email and password using firebase
        createUserWithEmailAndPassword(authentication, e.UserName, e.Password)
            .then(() => {
                const uid = authentication.currentUser.uid;
                const userCourseRef = doc(db, 'userCourses', uid);
                setDoc(userCourseRef, { CreatedOn: Timestamp.now() })
                courses.forEach(({ course, id }) => {
                    if (course.type === "Online") {
                        setDoc(doc(db, "userCourses/" + uid + "/Courses/", course.id), {
                            id: course.id,
                            Title: course.title,
                            Started: false,
                            Completed: false,
                            Enrolled: true,
                            Paid: false,
                            ChapterNumber: 0,
                        })
                    }
                })

                updateProfile(authentication.currentUser, { displayName: e.Name })
                    .then(() =>
                        sendEmailVerification(authentication.currentUser)
                            .then(() => {
                                setTimeActive(true)
                                navigate('/verify-email')
                            })
                    )
            })
            .catch(err => setError(err.message))

    }
    return (
        <>
            <SeoTags title={"KrisKisStyle | Registrate"} />
            <div className='container pb-5 mb-5'>
                <div className='container'>
                    <h1 className="font-weight-light text-center fs-3 py-5 mt-5"> Crea tu cuenta para tener acceso a cursos, promociones y productos online </h1>{" "}
                    {error && <div className='alert danger'>{error}</div>}
                    <form onSubmit={handleSubmit(onSubmit)} className="w-75 mx-auto" >
                        <div className="input-group-sm">
                            <label htmlFor="Name" className="col-sm col-form-label col-form-label-sm text-highlight">Tu Nombre</label>
                            <input className='form-control' type="text" placeholder="" {...register("Name", { obligatorio: { value: true, message: "Introducir el nombre es obligatorio" }, maxLength: { value: 180, message: "180 characters maximum" } })} />
                            {errors.UserName && <p className="text-danger font-weight-light col-form-label-sm" > {" - " + errors.Name.message} </p>}
                        </div>
                        <div className="input-group-sm">
                            <label htmlFor="UserName" className="col-sm col-form-label col-form-label-sm text-highlight">Correo Electrónico</label>
                            <input className='form-control' type="email" placeholder="" {...register("UserName", { obligatorio: { value: true, message: "Introducir el usuario es obligatorio" }, maxLength: { value: 180, message: "180 characters maximum" } })} />
                            {errors.UserName && <p className="text-danger font-weight-light col-form-label-sm" > {" - " + errors.UserName.message} </p>}
                        </div>
                        <div className="input-group-sm">
                            <label htmlFor="Password" className="col-sm-2 col-form-label col-form-label-sm text-highlight">Contraseña</label>
                            <input className='form-control' type="password" placeholder="" {...register("Password", { required: { value: true, message: "Introducir la contraseña es obligatorio" }, maxLength: { value: 120, message: "120 characters maximum" } })} />
                            {errors.Password && <p className="text-danger font-weight-light col-form-label-sm"> {" - " + errors.Password.message} </p>}
                        </div>
                        <div className="mx-auto w-100 text-center pt-3 pb-5">
                            <button className="btn btn-secondary m-3" type="submit">Crear</button>
                            <div className="d-flex justify-content-between container strech">
                                <Link to="/login" className='text-decoration-none text-highlight' >
                                    <span>Ya tengo cuenta</span></Link>
                                <Link to="/resetpassword" className='text-decoration-none text-highlight'>
                                    <span>Olvidé la contraseña</span>
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Register;