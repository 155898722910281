import React from "react";
import RenderIcon from '../util/RenderIcon';

function CourseChapterList(props) {

    const content = props?.content;
    const index = props?.index;
    const completed = props?.completed;

    return (
        <>
            <section className="mt-3 pt-5">
                <div id="CourseInfo" className="container-fluid pt-5">
                    <p>TEMARIO</p>
                    <div>
                        <ul className="list-group list-group-flush">
                            {content?.map((chapter, id) => (
                                <li key={id} className="list-group-item list-group-item-thirdary fs-8">
                                    <span> {chapter.title}: {chapter.description} </span>
                                    {((index !== null && index > id) || (completed)) &&
                                        <span className=""><RenderIcon size={"1x"} icon={"faCheckCircle"} /></span>
                                    }
                                    {index !== null && index === id && !completed &&
                                        < span > <RenderIcon size={"1x"} icon={"faClipboardList"} /></span>
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )

}

export default CourseChapterList;