import { useParams } from "react-router-dom";
import Contact from "../components/forms/Contact";
import SeoTags from "../util/SeoTags";
import LoadServiceInfo from "../util/LoadServiceInfo";
import parse from 'html-react-parser';

function ServiceInfo() {
    const { service } = useParams();
    const serviceInfo = LoadServiceInfo(service);
    const title = 'KrisKisStyle | ' + serviceInfo.title;
    
    return (
        <>
            <SeoTags title={title} description={"Maquillaje y Peinados por Kristina Malamova"}></SeoTags>
            <section className="container my-5 py-5 bg-primary rounded kkstyle-shadow ">
                <div className="container-fluid">
                    <div className="fs-3 text-highlight text-center"><p>Información sobre <b>{serviceInfo.moreInfoTitle}</b></p></div>
                    <div className="text-center py-5">
                        <img className="mx-auto service-image" src={serviceInfo.imageSrc} alt="not found"></img>
                    </div>
                    <div>
                        {serviceInfo.moreInfoText1 !== "" &&
                            <p className="paragraph-indent" >{parse(serviceInfo.moreInfoText1) }</p>
                        }
                        {serviceInfo.moreInfoText2 !== "" &&
                            <p className="paragraph-indent">{parse(serviceInfo.moreInfoText2) }</p>
                        }
                        {serviceInfo.moreInfoText3 !== "" &&
                            <p className="paragraph-indent">{parse(serviceInfo.moreInfoText3) }</p>
                        }
                        {serviceInfo.moreInfoText4 !== "" &&
                            <p className="paragraph-indent">{parse(serviceInfo.moreInfoText4) }</p>
                        }
                        {serviceInfo.moreInfoText5 !== "" &&
                            <p className="paragraph-indent">{parse(serviceInfo.moreInfoText5) }</p>
                        }
                    </div>
                </div>
            </section>
            <Contact />
        </>
    )
}

export default ServiceInfo;