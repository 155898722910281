import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authentication } from '../auth/Firestore'
import { signOut } from "firebase/auth";

function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        signOut(authentication).then((value) => {
            // console.log("signed out successfuly", value)
            navigate("/")
        }).catch((err) => {
            // console.log(err.message)
        })
    })

    return (
        <></>
    );
}

export default Logout;