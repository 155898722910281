import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";

import { Carousel } from "react-bootstrap";

function Terstimonials() {
    const interval = 5000
    return (
        <section className="container-fluid pb-5 mb-5 100vw">
            <Carousel className="bg-primary pt-5 text-highlight">
                <Carousel.Item interval={interval}>
                    <img src="/img/photos/desire.jpeg" className="d-block img-fluid service-image mx-auto" alt=".." />
                    <blockquote className="blockquote text-center pt-5">
                        <p className="mb-2 mx-5">
                            <i className="fa fa-quote-left text-highlight">
                                Fue super simpatica, me duró el maquillaje y el recogido más de 24h, hizo un gran
                                trabajo y además me dio un kit de retoques que no utilicé porque me duró todo y eso que lloré
                                mucho, quedé encantada.
                            </i>
                        </p>
                        <footer className="blockquote-footer">Desiré
                            <cite title="Source Title">Invitada de boda</cite>
                        </footer>
                        <p className="client-review-stars pb-5">
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                        </p>
                    </blockquote>
                </Carousel.Item>
                <Carousel.Item interval={interval}>
                    <img src="/img/photos/natalia.jpeg" className="d-block img-fluid service-image mx-auto" alt=".." />
                    <blockquote className="blockquote text-center pt-5">
                        <p className="mb-2 mx-5">
                            <i className="fa fa-quote-left text-highlight"> 
                            Una Gran Profesional que sabe potenciar la belleza, muy amable, agradable, puntual, excelente
                            en todo!! Y nosotras muuuuy agradecidas!! Repetiremos!!
                            </i>
                        </p>
                        <footer className="blockquote-footer">Natalia
                            <cite title="Source Title">Invitada de boda</cite>
                        </footer>
                        <p className="client-review-stars pb-5">
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStarHalfStroke}></FontAwesomeIcon>
                        </p>
                    </blockquote>
                </Carousel.Item>
                <Carousel.Item interval={interval}>
                    <img src="/img/photos/serena.jpeg" className="d-block img-fluid service-image mx-auto" alt=".." />
                    <blockquote className="blockquote text-center pt-5">
                        <p className="mb-2 mx-5">
                            <i className="fa fa-quote-left text-highlight">
                            Hola Kristina! Fue un día para no olvidar. Me dejaste guapísima! 
                            Y el kit me vino genial, sobretodo las toallitas absorbentes. Y mi madre
                            preciosa también. Mil gracias!
                        </i>
                        </p>
                        <footer className="blockquote-footer">Serena
                            <cite title="Source Title">Novia</cite>
                        </footer>
                        <p className="client-review-stars pb-5">
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                        </p>
                    </blockquote>
                </Carousel.Item>
                <Carousel.Item interval={interval}>
                    <img src="/img/photos/maria.jpeg" className="d-block img-fluid service-image mx-auto" alt=".." />
                    <blockquote className="blockquote text-center pt-5">
                        <p className="mb-2 mx-5">
                            <i className="fa fa-quote-left text-highlight">
                            Cristina!!!! Muchas gracias por las felicitaciones, las fotos, y por el excelente trabajo
                            del lunes. No podría estar más contenta. Trabajas genial y además eres majísima.
                        </i>
                        </p>
                        <footer className="blockquote-footer">María
                            <cite title="Source Title">Novia</cite>
                        </footer>
                        <p className="client-review-stars pb-5">
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                        </p>
                    </blockquote>
                </Carousel.Item>
            </Carousel>
        </section>
    )

}

export default Terstimonials;