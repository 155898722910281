
function LoadCourses(id) {

    const courseData =
    {
        "automaquillaje_presencial":
        {
            title: "Automaquillaje",
            description: "Descubre las posibilidades de maquillarte a diario o para un evento con destreza y facilidad. Aprende a maquillarte conmigo, tratamiento de piel, ojos de día y de noche, sácale provecho a tu neceser.",
            id: "course000",
            type: "Presencial",
            duration: "3h",
            price: 45,
            currency: "€",
            icon: "faPaintBrush",
            chapters: [
                {
                    key: "0",
                    title: "Capitulo 1",
                    description: "Introduccion al maquillaje",
                    type: "text",
                    text: "This is a long text for testing purposes, This is a long text for testing purposes, This is a long text for testing purposes",
                    src: "",
                },
                {
                    key: "1",
                    title: "Capitulo 2",
                    description: "Elegir productos",
                    type: "video",
                    src: "videos/videoOctubre2020.mp4",
                },
                {
                    key: "2",
                    title: "Capitulo 3",
                    description: "Tratamiento de la piel",
                    type: "video",
                    src: "videos/loc0003-vendido.mp4",
                },
                {
                    key: "3",
                    title: "Capitulo 3",
                    description: "Aplicar la base",
                    type: "video",
                    src: "videos/HC merchandise video.mp4",
                },
            ],
        },
    };

    if (id === null || id === "" || id === undefined) {

        const objArray = [];
        Object.keys(courseData).forEach(key => objArray.push({
            id: key,
            course: courseData[key]
        }));
        return objArray;
    }

    return courseData[id];
}

export default LoadCourses;