
import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import "yet-another-react-lightbox/styles.css";
import './css/custom.css';
import './css/style.css'

import { HelmetProvider } from 'react-helmet-async';
import Home from './pages/Home';
import AllPortolios from './pages/AllPortfolios';
import Courses from './pages/Courses';
import Profile from './pages/Profile';

import Navigation from './components/Navigation'
import Footer from './components/Footer';
import Login from './components/forms/Login';
import Register from './components/forms/Register';
import VerifyEmail from './components/forms/VerifyEmail';
import Learn from './components/Learn';
import Portfolio from './components/Portfolio';

import ProtectedRoute from './components/auth/ProtectedRoute';
import { onAuthStateChanged, } from 'firebase/auth';
import { collection, onSnapshot } from 'firebase/firestore';
import { authentication, db } from './components/auth/Firestore';
import { AuthContext } from './components/auth/AuthContext';
import { CourseContext } from './components/auth/CurseContext';
import CourseInfo from './pages/CourseInfo';
import CoursesMenu from './pages/CoursesMenu';
import ContactPage from './pages/ContactPage'
import Services from './pages/Services';
import Academy from './pages/Academy';
import PortfolioMenu from './pages/PortfolioMenu';
import Logout from './components/forms/Logout';
import ResetPassword from './components/forms/ResetPassword';
import ReactGA from 'react-ga4';
import { useCookieConsent } from '@porscheofficial/cookie-consent-banner-react';
import About from './pages/About';
import MoreInfo from './pages/MoreInfo';
import ServiceInfo from './pages/ServiceInfo';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [timeActive, setTimeActive] = useState(false);
  const [courses, setCourses] = useState(null);
  const acceptedCategories = useCookieConsent();
  const analyticsAcceptedCookie = useMemo(() => {
    return Boolean(acceptedCategories.includes("analytics"))
  }, [acceptedCategories])


  useEffect(() => {
    if (analyticsAcceptedCookie) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }

  }, [analyticsAcceptedCookie])

  useEffect(() => {
    onAuthStateChanged(authentication, (currentUser) => {
      setCurrentUser(currentUser)
    })
  }, [currentUser])

  useEffect(() => {
    if (currentUser?.emailVerified) {
      const uid = currentUser.uid;
      onSnapshot(collection(db, 'userCourses/' + uid + '/Courses'), (snapshot) => {
        setCourses(snapshot.docs.map((doc) => {
          return doc.data();
        }))
      })
    }
  }, [setCourses, currentUser])

  return (
    <>
      <React.StrictMode>
        <HelmetProvider>
          <AuthContext value={{ currentUser, timeActive, setTimeActive }}>
            <CourseContext value={{ courses, setCourses }}>
              <Router>
                <Navigation></Navigation>
                <Routes>
                  <Route index element={<Home />} />
                  <Route path='about' element={<About />} />
                  <Route path="info" element={<MoreInfo />}>
                    <Route path=':service' element={<ServiceInfo />} />
                  </Route>

                  <Route path="portfolio" element={<PortfolioMenu />}>
                    <Route path='' element={<AllPortolios />} />
                    <Route path=':gal' element={<Portfolio />} />
                  </Route>
                  <Route path="services" element={<Services />} />
                  <Route path="courses" element={<CoursesMenu />} >
                    <Route path='' element={<Courses />} />
                    <Route path=':courseTitle' element={<CourseInfo />} />
                  </Route>
                  <Route path="academy" element={<ProtectedRoute><Academy /></ProtectedRoute>} >
                    <Route path="" element={<ProtectedRoute><Academy /></ProtectedRoute>} />
                    <Route path=':courseTitle' element={<ProtectedRoute> <Learn /> </ProtectedRoute>} />
                  </Route>
                  <Route path="contact" element={<ContactPage />} />
                  <Route path="/login" element={
                    !currentUser?.emailVerified
                      ? <Login />
                      : <Navigate to='/' replace />
                  }>
                  </Route>
                  <Route path="/register" element={
                    !currentUser?.emailVerified
                      ? <Register />
                      : <Navigate to='/profile' replace />
                  } />
                  <Route path='/verify-email' element={<VerifyEmail />} />
                  <Route path="/resetpassword" element={
                    !currentUser?.emailVerified
                      ? <ResetPassword />
                      : <Navigate to='/profile' replace />
                  } />
                  <Route path="/logout" element={
                    currentUser?.emailVerified
                      ? <Logout />
                      : <Navigate to='/' replace />
                  }>
                  </Route>
                  <Route path="/profile" element={
                    <ProtectedRoute>
                      <Profile />
                    </ProtectedRoute>}
                  />
                  <Route path='*' element={<Home />} />
                </Routes>
                <Footer />
              </Router>
            </CourseContext>
          </AuthContext>
        </HelmetProvider>
      </React.StrictMode>
    </>
  );
}

export default App;
