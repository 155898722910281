import React from "react";
import { Outlet } from "react-router-dom";

function MoreInfo() {
  return (
    <>
          <Outlet />
    </>
  );
}

export default MoreInfo;
