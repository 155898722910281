import React, {useContext} from 'react'

const courseContxt = React.createContext()

export function CourseContext({children, value}) {
  return (
    <courseContxt.Provider value={value}>
      {children}
    </courseContxt.Provider>
  )
}

export function useCourseContext(){
  return useContext(courseContxt)
}