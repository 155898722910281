import React from "react";
import SeoTags from "../util/SeoTags";
import Portfolio from "../components/Portfolio";

function AllPortolios() {

    return (
        <>
        <SeoTags title={'KrisKissTyle | Portfolio'} description={"Maquillaje y Peinados por Kristina Malamova"} />
            <div className="mx-auto">
                <h4 className="mt-5">Novias</h4>
            </div>
            <Portfolio title="Novias" gallery="novias" />
            <div className="mx-auto pt-5 mt-5">
                <h4>Fallas</h4>
            </div>
            <Portfolio title="Fallas" gallery="fallas" />
            <div className="mx-auto pt-5 mt-5">
                <h4>Eventos</h4>
            </div>
            <Portfolio title="Eventos" gallery="eventos" />
            <div className="mx-auto pt-5 mt-5">
                <h4>Peinados</h4>
            </div>
            <Portfolio title="Peinados" gallery="peinado" />
            <div className="mx-auto pt-5 mt-5">
                <h4>Artístico</h4>
            </div>
            <Portfolio title="Artístico" gallery="artistico" />
            <div className="mx-auto pt-5 mt-5">
                <h4>Pestañas</h4>
            </div>
            <Portfolio title="Pestañas" gallery="pestanas" />
            <div className="mx-auto pt-5 mt-5">
                <h4>Sesiones de Fotos</h4>
            </div>
            <Portfolio title="Sesiones" gallery="sesiones" />
        </>
    );
}

export default AllPortolios;
