
function LoadServiceInfo(id) {

    const serviceData =
    {
        "tradicional":
        {
            id: "001",
            title: "Maquillaje Tradicional",
            description: "Para las ocasiones importante, hacemos todas las pruebas necesarias para darte un acabado único a tu maquillaje que exprese tu identidad, aplicando el maquillaje con productos de alta calidad y pincelería de primera clase.",
            imageSrc: "/img/photos/small_bodas.jpg",
            portfolio:false,
            image2Src: "",
            moreInfoTitle:"Maquillaje Tradicional y Novias",
            moreInfoText1:"El maquillaje tradicional es aquel que se aplica a través de brochas, pinceles, esponjas o los dedos, los cuales permiten aplicar, cubrir, esparcir y difuminar el maquillaje. Todo lo que se utiliza se aplica haciendo contacto con el rostro.",
            moreInfoText2:"<b>¿Te casas o tienes algún evento especial y quieres lucir la mejor imagen de ti en armonía? -  ¡Te puedo ayudar!</b>",
            moreInfoText3:"¿Como es el proceso?",
            moreInfoText4:"Comenzamos con un café si podemos quedar en persona perfecto, si no virtual. Donde nos conocemos y me cuentas todo sobre tu boda (celebración), como son tus gustos, tus ideas y resolvemos todas las dudas que puedas tener. Te explico mi manera de trabajar y te daré mis consejos.<br/>Si te ha gustado lo que hemos hablado formalizamos un contrato y la reserva, garantizándote la realización de los servicios.<br/>Desde ese momento tendremos contacto en cualquier momento, para lo que necesites, dudas, ideas de tu apariencia, estoy disponible para ti. ",
            moreInfoText5:"Aproximadamente un mes antes de la boda (evento) quedaremos para realizar las pruebas. En la cual vamos a ver todo lo que hace falta para llegar a ser la novia perfecta en mi estudio.<br/>En el gran día, tú solo tienes que preocuparte de disfrutar, yo me encargaré de darte el look soñado, tal y como lo hemos hablado. Te acompañare hasta el último momento, ayudándote con todo lo que necesitas.<br/><br/>Esta es una breve descripción de todos mis servicios y para todas mis clientas. Desde el punto de vista de una apasionada de la belleza, tanto novias como invitadas merecen brillar en cualquier evento.",
        },
        "eventos":
        {
            id: "002",
            title: "Maquillaje con Aerógrafo",
            description: "Decídete por maquillaje con aerógrafo, unos acabados suaves como nunca has visto, los sombreados que pasan desapercibidos o llaman la atención de todos, tú eliges.",
            imageSrc: "/img/photos/small_aerografo.jpg",
            portfolio:false,
            image2Src: "",
            moreInfoTitle:"Maquillaje con Aerógrafo",
            moreInfoText1:"El maquillaje con aerógrafo es una técnica que consiste en pulverizar el rostro o el cuerpo usando el aire como vehículo, en consecuencia, con esta técnica de aplicación no se llega a tocar la piel. Su aplicación es rápida y su durabilidad es intensa, su textura fina y da como resultado un acabado espectacular. No reseca la piel, ya que sus ingredientes son naturales y humectantes.",
            moreInfoText2:"Quieres tener esa experiencia, no dudes en contactarme y haremos una prueba, sin compromiso.",
            moreInfoText3:"",
            moreInfoText4:"",
            moreInfoText5:"",
        },
        "peinado":
        {
            id: "003",
            title: "Maquillaje y Peinado",
            description: "El combo maquillaje y peinado está diseñado para aquellos que quieren lucir lo mejor. Consigue un maquillaje y peinado que funciona en conjunto en la comodidad de tu hogar.",
            imageSrc: "/img/photos/peluqueria.jpg",
            portfolio:false,
            image2Src: "",
            moreInfoTitle:"Maquillaje y Peinado",
            moreInfoText1:" ¡Tenemos <b>packs especiales</b>!",
            moreInfoText2:"El día de tu boda tú eres la protagonista. Si deseas lucir espectacular contrata nuestros servicios de maquillaje y peluquería para novias, invitadas y madrinas.",
            moreInfoText3:"Aprovecha la comodidad para ser atendida por una persona que te conoce y te transmite seguridad y tranquilidad.",
            moreInfoText4:"",
            moreInfoText5:"",
        },
        "sesiones":
        {
            id: "004",
            title: "Sesiones de Fotos",
            description: "Si necesitas maquilladora con experiencia en tus sesiones de fotos, ya sea interiores o en ubicación, no dudes en llamarme.",
            imageSrc: "/img/photos/sesiones.jpg",
            portfolio:true,
            image2Src: "",
            moreInfoTitle:"Sesiones de Fotos",
            moreInfoText1:"La presencia de una buena Maquilladora detrás de la cámara es tan importante como el fotógrafo mismo, porque la preparación y la realización de un trabajo profesional es fundamental para obtener fotos que requieran <b>poca edición.</b>",
            moreInfoText2:"El maquillaje para una <b>sesión fotográfica</b> debe ser ligeramente más pronunciado que el básico que usamos para salir a la calle, debido a la intensidad de la luz dada por los focos.",
            moreInfoText3:"No te la juegues y cuenta con mi experiencia para tus fotos.",
            moreInfoText4:"",
            moreInfoText5:"",
        },
        "artistico":
        {
            id: "005",
            title: "Maquillaje Artístico",
            description: "Pasarlo bien en Carnavales, Halloween, o Moros y Cristianos por poner ejemplos, es siempre más satisfactorio cuando complementas tu atuendo con un maquillaje profesional.",
            imageSrc: "/img/photos/serviciosartistico.jpeg",
            portfolio:true,
            image2Src: "",
            moreInfoTitle:"Maquillaje Artístico",
            moreInfoText1:"Generalmente se utiliza para representaciones teatrales, eventos especiales, fiestas y carnavales.",
            moreInfoText2:"Este tipo de maquillaje tan pintoresco nos permite jugar con las <b>infinitas posibilidades</b> que nos dan los colores y las formas.",
            moreInfoText3:"Cuéntame tu idea y juntas realizamos y trabajamos el surrealismo lo máximo posible, buscando el <b>acabado único</b> y propio para lucir tu disfraz completo.",
            moreInfoText4:"",
            moreInfoText5:"",
        },
        "pestanas":
        {
            id: "009",
            title: "Extensiones de Pestañas",
            description: "Las extensiones son esenciales para que los ojos resalten al máximo, tengo todo tipo de pestañas, ven y pruébalas.",
            imageSrc: "/img/photos/pestanas.jpg",
            portfolio:true,
            image2Src: "",
            moreInfoTitle:"Extensiones de Pestañas",
            moreInfoText1:"Resalta tu mirada con extensiones de pestañas al estilo de <b>volúmen ruso</b> o pelo a pelo.",
            moreInfoText2:"Mejora la longitud, curvatura, cantidad y grosor de tus pestañas sin comprometer tu mirada natural.",
            moreInfoText3:"A domicilio o en mi estudio, donde encontrarás un entorno agradable para relajarte y te podré elegir las pestañas que más <b>se adaptan a ti.</b>",
            moreInfoText4:"",
            moreInfoText5:"",
        },
        "beauty-bar":
        {
            id: "006",
            title: "Beauty Bar",
            description: "Rincón de la belleza para retoque de invitados antes de la ceremonia. Hazle un detalle a tus invitados destinado al retoque del maquillaje a mano de una profesional.",
            imageSrc: "/img/photos/beautybar.jpg",
            portfolio:false,
            image2Src: "",
            moreInfoTitle:"Beauty Bar",
            moreInfoText1:"Es un rincón muy especial para todo tipo de eventos: bodas, fiestas, discotecas, festivales, cenas de empresa. Destinado a hacer retoques de maquillaje y decorar a los invitados con purpurina dentro del local de celebraciones.",
            moreInfoText2:"Regala el <b>Retocador</b> a tus invitadas y tenderán una profesional que retocara sus maquillajes y poder seguir disfrutando de la celebración sin la preocupación de si el maquillaje es duradero, si han salido brillos, o si las lágrimas de emoción han dejado huella en sus ojos.",
            moreInfoText3:"Te apetece algo todavía más divertido y llevar la imagen de los invitados a otro nivel, llenaré el espacio de <b>Glitter</b> que es una propuesta brillante y siempre hace las noches que pasen a otro nivel, dándo les glamour y diversidad.",
            moreInfoText4:"Deseas un poco de todo, sin problema y con muchas ganas convertiré el rincón a <b>Beauty Bar</b> de tus imaginaciones.<br/>¿Te has quedado con ganas de más información sobre los <b>Beauty Bar</b>?<br/>¿Estas pensando en contratar un Beauty Bar para tu Boda, Despedida, Evento o Fiesta?<br/>No dudes en contactar conmigo a través de este formulario de contacto.",
            moreInfoText5:"",
        },
        "beauty-gift":
        {
            id: "007",
            title: "Beauty Gift",
            description: "Regala belleza en forma de vales de maquillaje, peinado o cursos de maquillaje.",
            imageSrc: "/img/photos/regalo.jpeg",
            portfolio:false,
            image2Src: "",
            moreInfoTitle:"Beauty Gift",
            moreInfoText1:"Se acerca alguna fecha especial (Navidad, San Valentin, Cumpleaños)  y no sabes qué regalar?",
            moreInfoText2:"Puedes regalar alguno de nuestros servicios - <b>Packs de Maquillaje y Peinado, Curso de AutoMaquillaje.</b>",
            moreInfoText3:"",
            moreInfoText4:"",
            moreInfoText5:"",
        },
        "beauty-party":
        {
            id: "008",
            title: "Beauty Party",
            description: "¿Quieres sorprender a tu amiga para su cumpleaños o despedida con algo distinto? Maquíllate con tus amigas o deja que una profesional os guíe durante una divertida sesión.",
            imageSrc: "/img/photos/serviciobeautyparty.jpg",
            portfolio:false,
            image2Src: "",
            moreInfoTitle:"Beauty Party",
            moreInfoText1:"Una fiesta con un toque de estilo es el plan perfecto para un día de chicas, donde todas disfrutaréis de un taller de belleza personalizado con una botella de champagne para celebrar de todo lo que os podáis imaginar y brindar por ello.",
            moreInfoText2:"Si quieres hacer algo nuevo, diferente, innovador, fuera de lo común,… no lo dudes y contrátalo. Me adapto a tus deseos e imaginaciones para que sea una fiesta <b>ÚNICA</b> e inolvidable.",
            moreInfoText3:"¿Te has quedado con ganas de más información sobre el Beauty Party?<br/>¿Se acerca tu aniversario y quieres celebrarlo a lo grande con tus amigas, o a lo mejor ha llegado la despedida de soltera y te apetece algo distinto?<br/>No dudes en contactar conmigo a través de este formulario de contacto.",
            moreInfoText4:"",
            moreInfoText5:"",
        },
    };

    if (id === null || id === "" || id === undefined) {

        const objArray = [];
        Object.keys(serviceData).forEach(key => objArray.push({
            id: key,
            service: serviceData[key]
        }));
        return objArray;
    }

    return serviceData[id];
}

export default LoadServiceInfo;