import React, { useEffect } from "react";
import { NavLink } from "react-router-dom"
import { useAuthValue } from "../components/auth/AuthContext";
import { useCourseContext } from "../components/auth/CurseContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function Profile() {
    useEffect(() => {
        document.title = 'KrisKissTyle | Mi Cuenta';
    }, []);

    const { currentUser } = useAuthValue();
    const { courses } = useCourseContext();


    return (
        <section id="profile" className="container">
            <div className="mb-5 pb-5">
                <div className="card-group pt-1 mx-0 px-0">
                    <div className="row row-cols-1 row-cols-md-2">
                        <div className="col my-3">
                            <div className="card my-5 py-5 px-md-2 px-xl-3  mx-1 mx-md-2 d-flex flex-column h-100">
                                <div className="card-body d-flex flex-column">
                                    <h4 className="card-title mx-auto text-highlight text-center">Información de Usuario
                                    </h4>
                                    <hr />
                                    <p className=" fs-8 pt-sm-1 pt-md-2 pt-lg-3 pt-xl-4">Nombre: {currentUser?.displayName}</p>
                                    <p className=" fs-8 pt-sm-1 pt-md-2 pt-lg-3 pt-xl-4">Email: {currentUser?.email}</p>
                                    <p className=" fs-8 pt-sm-1 pt-md-2 pt-lg-3 pt-xl-4">Email Verificado: {currentUser?.emailVerified ? "Si" : "No"}</p>
                                </div>
                                <div className="card-body d-flex flex-column">
                                    <button className="btn btn-secondary">
                                        <NavLink className={"text-decoration-none"} to={"/logout"}>Logout</NavLink>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                            <div className="card my-5 py-5 px-md-2 px-xl-3 mx-1 mx-md-2 d-flex flex-column h-100">
                                <div className="card-body d-flex flex-column">
                                    <h4 className="card-title mx-auto text-highlight text-center">Mis Cursos</h4>
                                    <hr />
                                    <blockquote className="text-center fs-6 pt-md-2 pt-lg-3 pt-xl-4">Accede y continua con cualquiera de tus cursos</blockquote>
                                    {courses?.map(({ Title, id, Completed, Enrolled, Started, Paid }) => (
                                        <div key={id}>
                                            {Enrolled &&
                                                <div className="text-center">

                                                    {Paid && Completed &&
                                                        <>
                                                            <NavLink to={"/academy/" + Title.toLowerCase()} key={id} className="btn btn-thirdary mb-3 mx-auto text-center">{Title}</NavLink>
                                                            {" "}
                                                            < FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                        </>
                                                    }
                                                    {Paid && !Completed &&
                                                        <>
                                                            <NavLink to={"/academy/" + Title.toLowerCase()} key={id} className="btn btn-thirdary mb-3 mx-auto text-center">{Title}</NavLink>
                                                            {" "}
                                                            < FontAwesomeIcon icon={faClipboardList}></FontAwesomeIcon>
                                                        </>
                                                    }
                                                    {!Paid &&
                                                        <>
                                                            <NavLink to={"/courses/" + Title.toLowerCase()} key={id} className="btn btn-thirdary mb-3 mx-auto text-center">{Title}</NavLink>
                                                            {" "}
                                                            <OverlayTrigger
                                                                delay={{ hide: 450, show: 300 }}
                                                                overlay={(props) => (
                                                                    <Tooltip {...props}>
                                                                        Accede al curso para comprarlo.
                                                                    </Tooltip>
                                                                )}
                                                                placement="bottom">
                                                                < FontAwesomeIcon icon={faPaypal}></FontAwesomeIcon>
                                                            </OverlayTrigger>
                                                        </>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )

}

export default Profile;

/*

Sandbox Account
sb-2sl43d23444565@business.example.com
Client ID
AfbssHHxXUR8naOrYbRzx1hgVyxSFAd_ZzGmomV4UG26lkDdV7ArdKRgUdSUBNibTT-4Xt9h10lcBxYT
Secret
Hide
Note: When you generate a new secret, you still maintain the original secret. The maximum number of client secrets is two. A client secret is either in enabled or disabled state.

Created	Secret	Status	Action
13 Dec 2022	EEgky40eFRX4hTapRtQ81vBc2e2uOuKWtT7lhBOzmd_vj4tyrVvk3GIoGzRvs3ST1uX7o3VRjCPIYuBP

*/