import React, { useState } from "react";
import axios from "axios";

function Contact() {

    const [from, setFrom] = useState("");
    const [subject, setSubject] = useState("Maquillaje");
    const [body, setBody] = useState("");
    const [state, setState] = useState("");
    const mailPath = "/php/mail.php";

    const handleSubmit = event => {
        event.preventDefault();
        axios({
            method: 'post',
            url: mailPath,
            headers: { 'content-type': 'application/json' },
            data: { "from": from, "subject": subject, "body": body }
        })
            .then(result => {
                setState({
                    result: result.data,
                })
            })
            .catch(error => setState({ result: error.message }));
    }

    return (
        <section id="contacto" className="vh-75 vw-100 container">
            <div className="my-5 px-xl-5 px-lg-3 px-md-2 px-sm-1">
                <div className="contacto py-5 my-auto mx-lg-5 ">
                    <div className="row">
                        <div className="col mb-5 text-center text-highlight">
                            <h3>Formulario de Contacto</h3>
                        </div>
                    </div>
                    {state.result === "SUCCESS" ?
                        <div id="message" className="alert alert-success text-center py-5 mx-auto w-75" role="alert">
                            Muchas Gracias.
                        </div> : ""}
                    {state.result !== null && state.result !== undefined && state.result !== "" && state.result !== "SUCCESS" ?
                        <div id="message" className="alert alert-danger text-center py-5 mx-auto w-75" role="alert">
                            Hubo un error mandando el mensaje.<br />
                            Puedes refrescar la página e intentarlo de nuevo o prueba otro método al pie de esta página.
                        </div> : ""}
                    {(state.result === null || state.result === "" || state.result === null || state.result === undefined) &&
                        <>
                            <div className="row">
                                <div className="col-lg-12 my-auto pt-5 my-5 px-lg-5">
                                    <div id="contacto-text" className="header-content mx-auto">
                                        <h5 className="mb-0 mx-auto text-center lh-base">Cuentanos cómo podemos ayudarte, describe lo
                                            que necesitas y dinos las fechas y dónde, de Valencia a Alicante, nos necesitas. Te responderemos lo antes
                                            posible para concretar el trabajo sin compromiso.
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 my-5 mx-auto pt-5 px-lg-5 container">
                                    <form onSubmit={handleSubmit} id="form1" className="text-center">
                                        <div className="form-group form-group-sm input-group-sm mx-0 pb-4">
                                            <label className="text-highlight" htmlFor="email">Tu correo electrónico:</label>
                                            <input type="email" className="form-control" id="from" onChange={(e) => { setFrom(e.target.value) }} placeholder="tunombre@ejemplo.com" />
                                        </div>
                                        <div className="input-group-sm mx-0 pb-4">
                                            <label className="text-highlight" htmlFor="subject">Presupuesto o Consulta sobre: </label>
                                            <select className="form-control custom-select" id="subject" onChange={(e) => { setSubject(e.target.value) }}>
                                                <option>Maquillaje</option>
                                                <option>Maquillaje y peinado</option>
                                                <option>Maquillaje con aerógrafo </option>
                                                <option>Sesiones de fotos</option>
                                                <option>Extensiones de pestañas</option>
                                                <option>Maquillaje artístico</option>
                                                <option>Hacer un Curso</option>
                                                <option>Otro Motivo</option>
                                            </select>
                                        </div>
                                        <div className="form-group form-group-sm input-group-sm mx-0 pb-5 mb-5">
                                            <label className="text-highlight" htmlFor="body">Descripción de tu consulta:</label>
                                            <textarea className="form-control" id="body" onBlur={(e) => { setBody(e.target.value) }} />
                                        </div>
                                        <button id="submitBtn" type="submit" name="submitBtn"
                                            className="btn btn-secondary pt-2 mt-5 mx-auto">Enviar</button>
                                    </form>
                                    <div id="message" className="alert alert-success d-none text-center w-100" role="alert">
                                        Muchas Gracias.
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </section >
    )

}

export default Contact;