import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Contact from "../components/forms/Contact";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useCourseContext } from "../components/auth/CurseContext";
import { useAuthValue } from "../components/auth/AuthContext";
import { updateCourse } from "../components/auth/Firestore";
import CourseChapterList from "../components/CourseChapterList";
import LoadCourses from "../util/LoadCourses";
import RenderIcon from "../util/RenderIcon";

function CourseInfo() {
    const { courseTitle } = useParams();
    const { courses } = useCourseContext();
    const [course, setCourse] = useState(null);
    const { currentUser } = useAuthValue();
    const [content, setContent] = useState(null);
    const [client, setClient] = useState(null);
    const navigate = useNavigate();

    const paypalOptions = {
        "client-id": "AfbssHHxXUR8naOrYbRzx1hgVyxSFAd_ZzGmomV4UG26lkDdV7ArdKRgUdSUBNibTT-4Xt9h10lcBxYT",
        currency: "EUR",
        intent: "capture",
    }

    useEffect(() => {
        document.title = 'KrisKissTyle | Info Curso';
    }, []);

    useEffect(() => {
        const tempCourse = courses?.filter(item => item.Title.toLowerCase() === courseTitle?.toLowerCase())
        if (tempCourse !== undefined) {
            setCourse(tempCourse[0]);
        }
        const tempContent = LoadCourses(courseTitle)
        setContent(tempContent);

    }, [courseTitle, courses, setCourse, setContent])

    useEffect(() => {
        if (client !== null) {
            updateCourse(currentUser.uid, { id: course.id, Paid: true, Started: true, Paypal: true, })
            navigate("/academy/" + course.Title.toLowerCase());
        }
    }, [client, navigate, currentUser, course])


    return (
        <>
            <section id="CourseInfo" className="w-100">
                <div className="">
                    <Card className="text-center">
                        <Card.Header className="text-highlight">NOVEDAD</Card.Header>
                        <Card.Body>
                            <Card.Title className="">Curso de {content?.title}</Card.Title>
                            <hr />
                            <div className={"my-5 py-5"} >
                                <RenderIcon size={"5x"} icon={content?.icon} />
                            </div>
                            <Card.Text>
                                {content?.description}
                            </Card.Text>
                            <br /><br />
                            <hr />
                            {content?.type === "Online" &&
                                <>
                                    <Card.Body >
                                        <CourseChapterList content={content?.chapters} />
                                    </Card.Body>
                                    <br />
                                </>
                            }
                            <br />
                            <Button variant="secondary" className="btn-thirdary">
                                <Link className={"text-decoration-none"} to={"#contacto"}>Más información</Link>
                            </Button>
                            <br /><br />
                            <p className="text-highlight">{content?.price} {content?.currency} </p>
                            <br />
                            {!course?.Paid && currentUser !== null &&
                                <PayPalScriptProvider options={paypalOptions}>
                                    <PayPalButtons style={{ layout: "vertical", shape: "pill", tagline: false }}
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            value: "45.00",
                                                        },
                                                    },
                                                ],
                                            });
                                        }}
                                        onApprove={(data, actions) => {
                                            return actions.order.capture().then((details) => {
                                                // const name = details.payer.name.given_name;
                                                if (details.status === "COMPLETED")
                                                    setClient(details.payer)
                                                // console.log(details)
                                            });
                                        }}
                                    ></PayPalButtons>
                                </PayPalScriptProvider>
                            }
                            {course === null && currentUser === null &&
                                <Button variant="secondary" className="btn-thirdary">
                                    <Link className={"text-decoration-none"} to={"/register"}>Crea una Cuenta</Link>
                                </Button>
                            }
                            {course?.Paid && currentUser != null &&
                                <Card.Text>
                                    Accede a este curso desde tu Perfil
                                </Card.Text>
                            }
                        </Card.Body>
                        <Card.Footer className="text-muted">{content?.type} ({content?.duration})</Card.Footer>
                    </Card>
                </div>
            </section>
            <Contact></Contact>
        </>
    )

}

export default CourseInfo;
