import React from "react";
import { Outlet } from "react-router-dom";
import SeoTags from "../util/SeoTags";

function CoursesMenu() {
  return (
    <>
      <SeoTags title={"KriskisStyle | Cursos"} />
      <div className="">
        <div className="container">
          <Outlet />
        </div>{" "}
      </div>
    </>
  );
}

export default CoursesMenu;
