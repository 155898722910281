import React from "react";
import { Outlet } from "react-router-dom";
import SeoTags from "../util/SeoTags";

function PortfolioMenu() {
    return (
        <>
            <SeoTags title={'KrisKissTyle | Portfolio'} description={"Maquillaje y Peinados por Kristina Malamova"} />
            <div className="">
                <div className="container">
                    <Outlet />
                </div>{" "}
            </div>
        </>
    );
}

export default PortfolioMenu;
