import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';

import { useAuthValue } from "./auth/AuthContext";

function Navigation() {
    const location = useLocation()

    const { currentUser } = useAuthValue();
    // console.log(currentUser);

    currentUser?.reload();

    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth" })
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
    }, [location,]);

    return (
        <Navbar collapseOnSelect expand="xxl" fixed="top" >
            <Container>
                <Navbar.Brand className="" href="/" > Kristina Malamova</Navbar.Brand>
                <Navbar.Toggle aria-controls="menucontent"></Navbar.Toggle>
                <Navbar.Collapse id="menucontent">
                    <Container >
                        <Nav className="ml-auto my-2 my-lg-0 justify-content-end" >
                            <NavDropdown className="" title="Portfolio">
                                <NavDropdown.Item href="/portfolio">Todas</NavDropdown.Item>
                                <NavDropdown.Divider></NavDropdown.Divider>
                                <NavDropdown.Item href="/portfolio/novias">Novias</NavDropdown.Item>
                                <NavDropdown.Item href="/portfolio/eventos">Eventos</NavDropdown.Item>
                                <NavDropdown.Item href="/portfolio/peinado">Peinados</NavDropdown.Item>
                                <NavDropdown.Item href="/portfolio/fallas">Fallas</NavDropdown.Item>
                                <NavDropdown.Item href="/portfolio/artistico">Artístico</NavDropdown.Item>
                                <NavDropdown.Item href="/portfolio/sesiones">Sesiones</NavDropdown.Item>
                                <NavDropdown.Item href="/portfolio/television">Television</NavDropdown.Item>
                                <NavDropdown.Item href="/portfolio/pestanas">Pestañas</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/services">Servicios</Nav.Link>
                            <Nav.Link href="/courses">Cursos</Nav.Link>
                            {/* <Nav.Link href="/about" style={{whiteSpace:"nowrap",overflow:"hidden" }} >Sobre Mí</Nav.Link> */}
                            <Nav.Link href="/contact">Contacto</Nav.Link>
                            {!currentUser?.emailVerified &&
                                <Nav.Link href="/login">Login</Nav.Link>
                            }
                            {currentUser?.emailVerified &&
                                <Nav.Link href="/profile">Perfil</Nav.Link>
                            }
                        </Nav>
                    </Container>
                </Navbar.Collapse>
            </Container >
        </Navbar >
    )

}

export default Navigation;