import React, { useState, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { authentication } from '../auth/Firestore';
import { sendPasswordResetEmail } from 'firebase/auth';
import SeoTags from '../../util/SeoTags';

function ResetPassword() {
    const { register, handleSubmit, formState: { errors } } = useForm({});
    const [error, setError] = useState('')
    const navigate = useNavigate()

    const onSubmit = e => {
        //   e.preventDefault()
        // console.log(e);
        sendPasswordResetEmail(authentication, e.UserName)
            .then(() => {
                navigate("/");
            })
            .catch((error) => {
                const code = error.code;
                switch (code) {
                    case "auth/user-not-found":
                        setError("Usuario no existe. Crea una nueva cuenta.")
                        return;
                    default:
                        setError("Ha habido algún error, puedes crear una cuenta nueva.")
                        return;
                }
            });
    }

    return (
        <>
            <SeoTags title={"KrisKisStyle | Recupera Contraseña"} />
            <div className='pb-5'>
                <div className=''>
                    <h1 className="font-weight-light text-center my-5 py-5 fs-3"> Introduce el correo electrónico que usaste para registrarte. </h1>{" "}
                    {error && <div className='auth__error'>{error}</div>}
                    <form onSubmit={handleSubmit(onSubmit)} className="w-75 mx-auto" >
                        <div className="input-group-sm">
                            <label htmlFor="UserName" className="col-sm col-form-label col-form-label-sm text-highlight">Correo Electrónico</label>
                            <input className='form-control' type="text" placeholder="ejemplo@gmail.com" {...register("UserName", { obligatorio: { value: true, message: "Introducir el usuario es obligatorio" }, maxLength: { value: 180, message: "180 caracteres de máximo" } })} />
                            {errors.UserName && <p className="text-danger font-weight-light col-form-label-sm" > {" - " + errors.UserName.message} </p>}
                        </div>
                        <div className="mx-auto w-100 text-center pt-3 pb-5">
                            <button className="btn btn-secondary m-3" type="submit">Enviar</button>
                            <div className="d-flex justify-content-between stretch flex-column">
                                <Link to="/register" className='text-decoration-none text-highlight' >
                                    <span>Crea una cuenta</span>
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;