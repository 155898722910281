import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom"
import { useAuthValue } from "./auth/AuthContext";
import { useCourseContext } from "../components/auth/CurseContext";
import { getDownloadURL, ref } from "firebase/storage";
import { storage, updateCourse } from './auth/Firestore';

import LoadCourses from '../util/LoadCourses';
import CourseChapterList from "./CourseChapterList";

function Learn() {
    const { courseTitle } = useParams();
    const { currentUser } = useAuthValue()
    const { courses } = useCourseContext();
    const [course, setCourse] = useState(null);
    const [content, setContent] = useState(null);
    const [index, setIndex] = useState(null);
    const videoRef = useRef();
    const [url, setUrl] = useState(null);

    useEffect(() => {
        document.title = 'KrisKissTyle | Curso';
    }, [courseTitle]);

    useEffect(() => {
        const tempCourse = courses?.filter(item => item.Title.toLowerCase() === courseTitle?.toLowerCase())[0];
        if (tempCourse !== undefined && tempCourse !== null) {
            setCourse(tempCourse);
            setIndex(tempCourse.ChapterNumber);
        }
    }, [courseTitle, courses, setCourse, setIndex]);

    useEffect(() => {
        if (course !== null && course !== undefined) {
            const tempContent = LoadCourses(course?.Title.toLowerCase())
            setContent(tempContent);
        }
    }, [course, setContent])

    useEffect(() => {
        if (content !== undefined && content !== null) {
            const selectedChapter = content?.chapters?.filter(item => item.key === index.toString())[0];

            if (selectedChapter?.type === "video") {
                getDownloadURL(ref(storage, selectedChapter.src))
                    .then((url) => {
                        setUrl(url);
                    })
                    .catch((error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/object-not-found':
                                // console.log(" File doesn't exist");
                                break;
                            case 'storage/unauthorized':
                                // console.log("User doesn't have permission to access the object");
                                break;
                            case 'storage/canceled':
                                // console.log("User canceled the upload");
                                break;

                            // ...

                            default:
                                // console.log("Unknown error occurred, inspect the server response");
                                break;
                        }
                    });
            }
        }
    }, [index, content, setUrl]);

    useEffect(() => {
        videoRef.current?.load();
    }, [url]);

    const handlePrev = () => {
        // setIndex(index - 1)
        updateCourse(currentUser.uid, { id: course.id, ChapterNumber: index - 1 })
    }
    const handleNext = () => {
        updateCourse(currentUser.uid, { id: course.id, ChapterNumber: index + 1 })
    }

    const handleCompletedCourse = () => {
        if (index === course.ChapterNumber) {
            updateCourse(currentUser.uid, { id: course.id, Completed: true });
        }
    }

    if (course === undefined || course === null || !course?.Paid) return null;

    if (content === null || content === undefined) return null;

    return (
        <>
            <section className="mt-3 mb-5 py-5 container">
                <div id="Learn" className="row w-100 my-5">
                    <div className="col">
                        <p className="fs-5">Curso de {content.title} Online</p>
                        <p className="fs-8">{content?.chapters[index]?.description}</p>
                    </div>
                </div>
                <div className="row text-justified">

                    <div className="col mx-auto my-5">
                        {content?.chapters[index]?.type === "text" &&
                            <div className="mx-auto">
                                {content?.chapters[index]?.text}
                            </div>
                        }

                        {content?.chapters[index]?.type === "video" &&
                            <div className="text-center mx-auto">
                                <video
                                    ref={videoRef}
                                    className="w-100"
                                    controls
                                    onEnded={handleCompletedCourse}
                                >
                                    <source src={url} type={"video/mp4"} />
                                </video>
                            </div>
                        }
                    </div>
                </div>
                <div className="row mx-auto text-center">
                    <div className="col mx-auto">
                        {index > 0 &&
                            <button className="btn btn-primary" onClick={handlePrev} >Anterior</button>
                        }
                    </div>
                    <div className="col mx-auto">
                        {index < content?.chapters?.length - 1 &&
                            <button className="btn btn-primary" onClick={handleNext} >Siguiente</button>
                        }
                    </div>
                </div>
                {content?.chapters &&
                    <div className="row text-center mb-3">
                        <div className="col">
                            <CourseChapterList content={content?.chapters} index={index} completed={course.Completed} />
                        </div>
                    </div>
                }
            </section>
        </>
    )

}

export default Learn;