
import {
    CookieConsentBanner,
    triggerCookieConsentBanner,
} from "@porscheofficial/cookie-consent-banner-react";

function KKSCookie({ children }) {

    return (
        <div>

            <div>{children}</div>
            <CookieConsentBanner
                // handlePreferencesUpdated={initConsent}
                // handlePreferencesRestored={initConsent}
                btnLabelAcceptAndContinue="Acepta y Continúa"
                btnLabelSelectAllAndContinue="Selecciona Todo y Continúa"
                btnLabelOnlyEssentialAndContinue="Continúa solo con los requisitos técnicos"
                btnLabelPersistSelectionAndContinue="Guarda la Selección y Continúa"
                contentSettingsDescription="Puedes decidir qué cookies utiliza la página seleccionando las opciones de abajo. Tenga en cuenta que sus preferencias pueden impedir su correcto funcionamiento en la web."
                availableCategories={[
                    {
                        description:
                            "Habilita la navegación, funciones básicas de la página y guardar sus preferencias.",
                        key: "technically_required",
                        label: "Cookies necesarias",
                        isMandatory: true,
                    },
                    {
                        description:
                            "Nos permiten determinar cómo utilizan la página nuestros visitantes para mejorar su experiencia.",
                        key: "analytics",
                        label: "Cookies analíticas",
                        isChecked: true,
                    },
                    // {
                    //     description:
                    //         "Enable us to offer and evaluate relevant content and interest-based advertising.",
                    //     key: "marketing",
                    //     label: "Marketing cookies",
                    // },
                ]}
            >
                Uilizamos cookies para mejorar la experiencia de usuario y ofrecer contenido relevante. Haciendo click en &quot;Aceptar y Continúar&quot; declara su consentimiento al uso de esas cookies.
                <button
                    onClick={() => {
                        triggerCookieConsentBanner({ showDetails: true });
                    }}
                    onKeyDown={() => {
                        triggerCookieConsentBanner({ showDetails: true });
                    }}
                    type="button"
                >
                    Aquí
                </button> puede configurar su elección.
            </CookieConsentBanner>
        </div>
    )
}


export default KKSCookie;