import React from "react";
import SeoTags from "../util/SeoTags";
import Contact from "../components/forms/Contact";

function CoursesMenu() {
    return (
        <>
            <SeoTags title={"KriskisStyle | Contacto"} />
            <Contact></Contact>
        </>
    );
}

export default CoursesMenu;
