import React, { useEffect, useState } from "react";
import { Link, } from "react-router-dom"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Contact from "../components/forms/Contact";
import LoadCourses from "../util/LoadCourses";

function Courses() {
    const [content, setContent] = useState(null)
    useEffect(() => {
        const tempContent = LoadCourses(null);

        // console.log("tempContent: ", tempContent);
        setContent(tempContent);
    }, [setContent])

    if (content === null || content === undefined || content?.length === 0)
        return (
            <Contact></Contact>
        );

    return (
        <>
            <section id="coursesInfo" className="container-fluid ">
                {content?.map((course, id) => {
                    
                    return (
                        <div key={id} className="pt-5">
                            <Card className="text-center">
                                <Card.Header className="text-highlight">NOVEDAD</Card.Header>
                                <Card.Body className="mx-lg-5">
                                    <Card.Title className="pb-3">Curso de {course.course.title}</Card.Title>
                                    <hr className="pb-3" />
                                    {/* <RenderIcon size="5x" icon={course.course.icon} /> */}
                                    <img src="/img/photos/cursos.jpg" className="img-fluid service-image mx-auto mb-4" alt=".." />
                                    <br /><br />
                                    <Card.Text>
                                        {course.course.description}
                                        {/* <p className="text-highlight py-3">{course.course.price}{course.course.currency}</p> */}
                                    </Card.Text>
                                    {course.course.type === "Online" &&
                                        <Button variant="secondary" className="btn-thirdary">
                                            <Link style={{ textDecoration: 'none' }} to={"/courses/" + course.course.title.toLowerCase()}>Más información</Link>
                                        </Button>
                                    }
                                    {course.course.type === "Presencial" &&
                                        <div className="pt-5">
                                            <Button variant="secondary" className="btn-thirdary">
                                                <Link style={{ textDecoration: 'none' }} to={"#contacto"}>Más información</Link>
                                            </Button>
                                        </div>
                                    }
                                </Card.Body>
                                <Card.Footer className="text-muted">{course.course.type} ({course.course.duration})</Card.Footer>
                            </Card>
                        </div>
                    )
                }
                )}
            </section>
            <Contact></Contact>
        </>
    )
}

export default Courses;